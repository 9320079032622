(function (global) {
    'use strict';

    var $ = global.$, pleaseWait;

    if (typeof $.fn.modal === 'function') {

        $("#pleasewait").modal({
            backdrop: 'static',
            keyboard: false,
            show: false
        });

        pleaseWait = function(msg) {
            if (msg === 0) {
                $("#pleasewait").modal('hide');
                return;
            }

            if (typeof msg === 'string') {
                $("#pleasewait span").html(msg);
            } else {
                $("#pleasewait span").html("Processing...");
            }

            $("#pleasewait").modal('show');
        }

        global.pleaseWait = pleaseWait;

        if (typeof global.define === 'function') {
            define('pleaseWait', function() { return pleaseWait; });
        }

        return global.pleaseWait;
    }

    if (typeof $.fn.dialog === 'function') {
        pleaseWait = function(s) {
            var text, show, u, z, pw = $("#pleasewait");

            u = typeof s === 'undefined';
            z = !(s == false);

            show = u || z;

            if (!show) {
                pw.dialog("close");
                return;
            }

            text = (u || !z) ? "Processing..." : s;
            pw.find("span").text(text);


            if (!pw.is(":visible")) {
                pw.dialog({
                    show: 'fade',
                    hide: 'fade',
                    resizable: false,
                    height: 100,
                    width: 320,
                    modal: true,
                    closeText: '',
                    bgiframe: true,
                    closeOnEscape: false,
                    open: function(event, ui) { $(".ui-dialog-titlebar-close", ui.dialog).hide(); }
                });
            }
        };

        global.pleaseWait = pleaseWait;

        if (typeof global.define === 'function') {
            define('pleaseWait', function () { return pleaseWait; });
        }

        return global.pleaseWait;
    }

    global.pleaseWait = function(s) {
        if (global.console && typeof global.console.log === 'function') {
            global.console.log(s);
        }
    };

    if (typeof global.define === 'function') {
        define('pleaseWait', function () { return pleaseWait; });
    }

    return global.pleaseWait;
}(window));


