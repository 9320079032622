(function ($) {

    var password_Strength = new function() {

        //return count that match the regular expression
        this.countRegExp = function(passwordVal, regx) {
            var match = passwordVal.match(regx);
            return match ? match.length : 0;
        }

        this.getStrengthInfo2 = function (passwordVal, container, password_settings) {
            $.post(
                "/Account/GetPasswordStrength",
                { str: passwordVal }).done(
                function (data) {
                    var strengthPercent = 0;
                    var barWidth = password_settings.barWidth;
                    var backColor = password_settings.barColor;

                    strengthPercent = (data.Score / data.MaxScore) * (barWidth);
                    
                    $("[id='PasswordStrengthBorder']").css({ display: 'inline', width: barWidth + 4 });

                    //use multiple colors
                    if (password_settings.useMultipleColors === "1") {
                        //first 33% is red
                        if (parseInt(strengthPercent) >= 0 && parseInt(strengthPercent) <= (barWidth * .33)) {
                            backColor = "red";
                        }
                            //33% to 66% is blue
                        else if (parseInt(strengthPercent) >= (barWidth * .33) && parseInt(strengthPercent) <= (barWidth * .67)) {
                            backColor = "blue";
                        }
                        else {
                            backColor = password_settings.barColor;
                        }
                    }

                    $("[id='PasswordStrengthBar']").css({ display: 'inline', width: strengthPercent, 'background-color': backColor });

                    //remove last "," character
                    if (data.Score < data.PassScore) {
                        password_settings.metRequirement = false;
                        container.text(data.Description);
                        //if requirements not met
                        if (data.Score < 3) {
                            alert(data.Policy);
                        } else {
                            alert("Build the strength of the password by using different characters and increasing the length of the password.");
                        }
                    }
                    else {
                        password_settings.metRequirement = true;
                        container.text(data.Description);
                    }
                });
        }

        
    }

    //default setting
    var password_settings = {
        barWidth: 200,
        barColor: 'Green',
        metRequirement: true,
        useMultipleColors: '1'
    };

    //password strength plugin 
    $.fn.password_strength = function(options) {

        password_settings = $.extend({}, $.fn.password_strength.defaultOptions, options);

        //check if password met requirement
        this.metReq = function() {
            return password_settings.metRequirement;
        }

        return this.each(function() {

            //bar position
            var barLeftPos = 0;
            var barTopPos = 0;

            //password indicator text container
            var container = $('<span></span>')
            .css({ position: 'absolute', top: barTopPos, left: barLeftPos, 'font-size': '75%', display: 'inline-block', width: password_settings.barWidth + 40 });

            //add the container next to textbox
            $("#pwsbar").append(container);

            //bar border and indicator div
            //var passIndi = $('<div id="PasswordStrengthBorder"></div><div id="PasswordStrengthBar" class="BarIndicator"></div>')
            //    .css({ position: 'absolute', display: 'none' })
            //    .eq(0).css({ height: 9, top: barTopPos-10, left: barLeftPos, 'border-style': 'solid', 'border-width': 1, padding: 2 }).end()
            //    .eq(1).css({ height: 5, top: barTopPos - 8, left: barLeftPos + 2 }).end();

            var passIndi = $('<div id="PasswordStrengthBorder"></div><div id="PasswordStrengthBar" class="BarIndicator"></div>')
                .css({ position: 'absolute', display: 'none' })
                .eq(0).css({ top: barTopPos - 15, left: barLeftPos + 5, 'border-style': 'solid', 'border-width': 1, padding: 2 }).end()
                .eq(1).css({ height: 4, top: barTopPos - 14, left: barLeftPos + 6 }).end();

            //set max length of textbox
            //$("[id='" + this.id + "']").attr('maxLength', password_settings.maxLength);

            //add the boder and div
            container.before(passIndi);

            $(this).blur(function () {

                var passwordVal = $(this).val(); //get textbox value

                //set met requirement to false
                password_settings.metRequirement = false;
                password_Strength.getStrengthInfo2(passwordVal, container, password_settings);
                //if (passwordVal.length > 0) {
                //    password_Strength.getStrengthInfo2(passwordVal, container, password_settings);
                //}
                //else {
                //    container.text('');
                //    $("[id='PasswordStrengthBorder']").css("display", "none"); //hide
                //    $("[id='PasswordStrengthBar']").css("display", "none"); //hide
                //}
            });
        });
    };

    $.fn.password_strength.defaultOptions = password_settings;

})(jQuery);
